import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const WeightPage = () => {
  return (
    <Layout>
      <h2>量り売り</h2>
      <p>
        お店のお菓子にも使用している、オーガニックの食材を１０ｇからお好きな分だけお求めいただけます。
      </p>
      <p>
        ナッツやドライフルーツ、冬季限定のチョコレートなど、店主のおすすめの食材を是非ご自宅でもお楽しみください◎
      </p>
      <p>
        量り売りの際は、ご自宅から瓶やタッパー、スタッシャーなどの入れ物をご持参ください。<br/>
        ゼロウェイストの普及をに目的としておりますので、お店では袋などのご用意はしておりません。<br />
        ご了承くださいm(_ _)m
      </p>
    </Layout>
  )
}

export const Head = () => <Seo title="Weight" />

export default WeightPage
